import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "./loader";


const FolderDataView = () : JSX.Element => {
  const [data, setData] =  useState<any>({ folderId: null, result: null, loading: false, error: null });
  const isNull = data.result != null;
  useEffect(() => {}, [isNull]);
  const handleChange = (event: any) => {
    setData({...data, folderId: event.target.value });
  };
  const handleSubmit = async () => {
    try {
      setData({ loading: true });
      if(data.folderId === null || data.folderId === undefined)  setData({ error: 'folderId can\'t be null', loading: false});
      if(data.folderId !== null && data.folderId !== undefined) {
        let params = {
          folderId: data.folderId
        }
        const response = await axios.get(     "https://4buyhqlrsc6gd73zbmrtciz3iu0mrcpt.lambda-url.eu-west-3.on.aws/",
          {
            headers: {
              "x-api-key": "0f2c7f4a0c674039bdca9d10d84c1777"
            },
            params
          }
        );
        const result = response.data.result;
        let obj = {};
        for (const props in result) {
          if (typeof data[props] === "object") {
            obj = { ...obj, [props]: result[props] };
          } else if(['gps', 'exif'].includes(props)) {
            obj = { ...obj, [props]: JSON.stringify(result[props]) };
          } else {
            obj = { ...obj, [props]: result[props] };
          }
        }
        setData({ ...data, result: obj, error: null });
      }
    } catch(err:any) {
      setData({ ...data, loading: false, error: err.response.data.error});
    }
  };

  return (
    <>
      {data.error && <div className={"error"}>{data.error}</div>}
      {data.result && (
        <div className="File">
          <table>
            <thead>
            <tr>
              <th>#</th>
              <th>Clé</th>
              <th>Valeur</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(data.result).map((e: [string, any], i: number) => (
              <tr key={e[0]}>
                <td>{i}</td>
                <td>{e[0]}</td>
                <td>
                  {e[0] === "match" ? (
                    e[1].toString()
                  ) : ["urlOrig", "urlHeatMap"].includes(e[0]) ? (
                    <a href={e[1]}>Lien</a>
                  ) : (
                    e[1]
                  )}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="folderId">
        <label htmlFor="folderId">Numéro de dossier</label>
        <input
          id="folderId"
          name="folderId"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div>
        {!data.loading ? (
          <button onClick={handleSubmit}>Récupérer</button>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default FolderDataView;




