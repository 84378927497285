import JSZip from "jszip"
import { saveAs } from "file-saver"
const JSZipUtils = require("jszip-utils")
const downloadZipFile = () => {
  JSZipUtils.getBinaryContent('/dataset-img.zip', async function (err: any, data: any) {
    if (err) {
      console.log(err)
    } else {
      let zip = new JSZip();
      await zip.loadAsync(data);
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "dataset-export.zip");
    }
  });
}
export default  downloadZipFile
