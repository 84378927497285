import { Dna } from "react-loader-spinner";
const Loader = (): JSX.Element => (
  <div className={"loader"}>
    <Dna
      visible={true}
      height="80"
      width="80"
      ariaLabel="dna-loading"
      wrapperStyle={{}}
      wrapperClass="dna-wrapper"
    />
  </div>
);

export default Loader;