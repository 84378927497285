import React from 'react'
import './App.css'
import Tabs from './tabs/tabs';
import FileUpload from './files/fileUpload';
import FolderDataView from './files/folderDataView';

export default function App() {
  return (
    <div className="App">
      <div className="appHeader">
        <img src={"/open.svg"} alt="Open Logo" />
        <h1>Démo Poc Atexo</h1>
      </div>
      <h2>Faites une Analyse</h2>
      <Tabs titles={["Envoyer un dossier", "Récupérer un dossier"]} components={[<FileUpload />, <FolderDataView />]} keys={['post', 'get']} />
    </div>
  );
}
