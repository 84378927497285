import React, { useState, useEffect } from "react";
import axios from 'axios';
import Loader from "./loader";
import downloadZipFile from './zip';
const File = (): JSX.Element => {
  const [file, setFile] =  useState<any>({ file: null, folderId: 0, data: null, loading: false, error: null, warn: null });
  useEffect(() => {}, [file])
  const handleChange = (event: any) => {
    setFile({...file, [event.target.id]: event.target.id === 'folderId' ? event.target.value :  event.target.files[0] });
  };
  const handleUpload = async () => {
    try {
      let formData = new FormData();
      setFile({ loading: true});
      formData.append("file", file.file);
      formData.append("folderId", file.folderId);

      const response = await axios.post( "https://4buyhqlrsc6gd73zbmrtciz3iu0mrcpt.lambda-url.eu-west-3.on.aws/",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            "x-api-key": "0f2c7f4a0c674039bdca9d10d84c1777"
          }
        }
      );
      const data = response.data.result;
      let obj = {};
      for (const props in data) {
        if(props === 'warn') continue
        if (typeof data[props] === "object" && !['gps', 'exif'].includes(props)) {
          obj = { ...obj, [props]: data[props].value };
        } else if(['gps', 'exif'].includes(props)) {
          obj = { ...obj, [props]: JSON.stringify(data[props]) };
        } else {
          obj = { ...obj, [props]: data[props] };
        }
      }
      setFile({ ...file, data: obj, error: null , warn: data.warn ?? null});
    } catch(err: any) {
      setFile({ ...file, loading: false, error: typeof err.response.data.error !== 'object' ? err.response.data.error : "An error occured" });
    }

  };
  return (
    <>
      {file.error && <div className={"error"}>{file.error}</div>}
      {file.warn && <div className={"warn"}>{file.warn}</div>}
      {!file.error && file.data && (
        <div className="File">
          <table>
            <thead>
            <tr>
              <th>#</th>
              <th>Clé</th>
              <th>Valeur</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(file.data).map((e: [string, any], i: number) => (
              <tr key={e[0]}>
                <td>{i}</td>
                <td>{e[0]}</td>
                <td>
                  {e[0] === "match" ? (
                    e[1].toString()
                  ) : ["urlOrig", "urlHeatMap"].includes(e[0]) ? (
                    <a href={e[1]}>Lien</a>
                  ) :
                    (
                    e[1]
                  )}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="folderId">
        <label htmlFor="folderId">Numéro de dossier</label>
        <input
          id="folderId"
          name="folderId"
          type="text"
          defaultValue={file.folderId}
          onChange={handleChange}
        />
      </div>
      <div>
        <input
          placeholder=""
          type="file"
          id="file"
          name="file"
          onChange={handleChange}
        />
      </div>
      <div>
        <button className={"download"} onClick={downloadZipFile}>Télécharger zip</button>
        {!file.loading ? (
          <button onClick={handleUpload}>Envoyer</button>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};
export default File;




