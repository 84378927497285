import React, { useState } from "react"

interface TabsI {
  titles: Array<string>
  components: Array<JSX.Element>,
  keys: Array<string>
}

const Tabs = ({ titles, components, keys }: TabsI):  JSX.Element  => {
  const [ activeClass, setActiveClass ] = useState("post active")
  const handleTabsClick = (event: any) => {
    if(event.target.className !== activeClass) {
      setActiveClass(`${event.target.className} active`)
    }
  }

  return <div className={'Tabs'}>
    <ul className={"nav"}>
      {titles.map((e: string, i: number) => {
        return <li className={activeClass === `${keys[i]} active` ? activeClass : `${keys[i]}`} key={`title-li-${keys[i]}`} onClick={handleTabsClick}>
          {e}
        </li>
      })}
    </ul>
    <ul className={'content'}>
      {components.map((e: JSX.Element, i: number) => {
        return <li className={activeClass === `${keys[i]} active` ? `content ${activeClass}` : `content ${keys[i]}`} key={`content-li-${i}`}>
          {e}
        </li>
      })}
    </ul>
  </div>
}

export default Tabs